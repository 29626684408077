





































import Vue from "vue";
import axios from "axios";
const calculate: Array<any> = [];
export default Vue.extend({
  data() {
    return {
      calculate: calculate,
      quantity: 10,
      mark: false,
      score: 0,
      duration: 0,
      startTime: 0,
    };
  },
  created() {
    // plus operation
    for (let i = 0; i < this.quantity * 2; i++) {
      const left = Math.round(Math.random() * 100);
      const right = Math.round(Math.random() * 100);
      this.calculate.push({
        leftNumber: left,
        rightNumber: right,
        operation: "+",
        result: "",
        correct: Math.round(left + right),
        right: false,
        finish: false,
      });
    }
    // minus operation
    for (let i = 0; i < this.quantity * 2; i++) {
      const left = Math.round(Math.random() * 100);
      const right = Math.round(Math.random() * 100);
      this.calculate.push({
        leftNumber: left > right ? left : right,
        rightNumber: left <= right ? left : right,
        operation: "-",
        result: "",
        correct: Math.abs(Math.round(left - right)),
        right: false,
        finish: false,
      });
    }
    // multiply operation
    for (let i = 0; i < this.quantity; i++) {
      const left = Math.round(Math.random() * 100);
      const right = Math.round(Math.random() * 100);
      this.calculate.push({
        leftNumber: left,
        rightNumber: right,
        operation: "×",
        result: "",
        correct: Math.round(left * right),
        right: false,
        finish: false,
      });
    }
    for(let i = 0; i < this.quantity; i++) {
      const result = Math.round(Math.random() * 100);
      const right = Math.round(Math.random() * 10);
      this.calculate.push({
        leftNumber: Math.round(result * right),
        rightNumber: right,
        operation: "÷",
        result: "",
        correct: result,
        right: false,
        finish: false,
      });
    }
    this.startTime = Math.round(Number(new Date()) / 1000);
  },
  methods: {
    getStatus(value: number, index: number) {
      this.calculate[index].right = value == this.calculate[index].correct;
      this.calculate[index].finish = true;
    },
    confirmAnswer() {
      this.mark = true;
      let great = 0;
      for (let i = 0; i < this.calculate.length; i++) {
        if (this.calculate[i].right) {
          great = great + 1;
        }
      }
      this.score = Math.round(great / 60 * 5);
      const endTime = Math.round(Number(new Date()) / 1000);
      this.duration = Math.round((endTime - this.startTime) / 60);
      this.sendEmail();
    },
    sendEmail() {
      // const content =  document.getElementById("homework")?.outerHTML;
      // const today = 
      let content = '<div style="font-size:18px;"><div>评分：'+ this.score + '星，大约花时间' + this.duration + '分钟</div><table border="1">'
      for(let i = 0; i < this.calculate.length; i++) {
        if(this.calculate[i].finish && this.calculate[i].right) {
          content = content + '<tr><td style="width: 200px;">' + this.calculate[i].leftNumber + this.calculate[i].operation + 
          this.calculate[i].rightNumber + '=' + this.calculate[i].result + '</td><td style="background-color: #19be6b;width: 100px;">正确</td></tr>'
        } else {
          content = content + '<tr><td  style="width: 200px;">' + this.calculate[i].leftNumber + this.calculate[i].operation + 
          this.calculate[i].rightNumber + '=' + this.calculate[i].result + '</td><td style="background-color: #ed4014;width: 100px;">错误</td></tr>'
        }
      }
      content = content + '</table></div>'
      axios
        .post("https://service.thinkmoon.cn/api/email/send", {
          subject: "计算结果报告",
          to: "limanshi1997@126.com",
          text: content,
        })
        .then((res) => {
          console.log(res);
        });
      axios
        .post("https://service.thinkmoon.cn/api/email/send", {
          subject: "计算结果报告",
          to: "2281589717@qq.com",
          text: content,
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
});
